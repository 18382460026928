import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { animateScroll as scroll } from 'react-scroll'

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Bio from '../components/bio'

import UpPng from '../../content/assets/up.png'

import './blog-post.css'

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolledPercentage: 0,
    }
    this.updateScrollProgress = this.updateScrollProgress.bind(this)
    this.scrollToTop = this.scrollToTop.bind(this)
    this.postFooter = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateScrollProgress)
    try {
      window.gtag('set', 'page_path', window.location.pathname)
      window.gtag('event', 'page_view')
    } catch (err) {}
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrollProgress)
  }

  updateScrollProgress = () => {
    const deviationInPercentage = 2
    const footerRect = this.postFooter.current.getBoundingClientRect()
    const footerDistanceInPx =
      footerRect.top - document.documentElement.clientHeight
    const scrollTopInPx = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    )
    const docHeigtInPxWithoutFooter = scrollTopInPx + footerDistanceInPx

    const scrolledPercentage = Math.ceil(
      (scrollTopInPx / docHeigtInPxWithoutFooter) * 100
    )
    // make up for the rounding error on mobile devices
    if (scrolledPercentage < deviationInPercentage) {
      this.setState({
        scrolledPercentage: 0,
      })
    } else if (100 - scrolledPercentage < deviationInPercentage) {
      this.setState({
        scrolledPercentage: 100,
      })
    } else {
      this.setState({
        scrolledPercentage,
      })
    }
  }

  scrollToTop = e => {
    e.preventDefault()
    scroll.scrollToTop({
      smooth: 'easeInOutCubic',
      duration: scrollDistanceInPx => Math.abs(scrollDistanceInPx / 4),
    })
  }

  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    const renderNoteAndDetailTable = () => {
      const attributes = ['Condition', 'Brand', 'Model', 'UPC', 'Quantity']
      if (attributes.some(attr => !!post.frontmatter[attr.toLowerCase()])) {
        const rows = []
        for (const attr of attributes) {
          if (post.frontmatter[attr.toLowerCase()]) {
            rows.push(
              <tr key={attr}>
                <th>{attr}</th>
                <td>{post.frontmatter[attr.toLowerCase()]}</td>
              </tr>
            )
          }
        }
        return (
          <div className="post-content-body">
            {post.frontmatter.note ? (
              <blockquote>{post.frontmatter.note}</blockquote>
            ) : null}

            <h5>Details</h5>
            <table className="full-width">
              <colgroup>
                <col className="col-header" />
                <col />
              </colgroup>
              <tbody>{rows}</tbody>
            </table>
          </div>
        )
      }
      return null
    }

    const renderFloatingBar = () => (
      <>
        <div
          style={
            this.state.scrolledPercentage === 0
              ? {
                  opacity: 0,
                  pointerEvents: 'none',
                }
              : null
          }
          className="floating-bar-button"
          onClick={this.scrollToTop}
        >
          <CircularProgressbarWithChildren
            value={this.state.scrolledPercentage}
            background
            strokeWidth={6}
            styles={buildStyles({
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'round',

              pathTransitionDuration: 0.4,

              // Colors
              pathColor: `#ecc111`,
              trailColor: 'rgba(0, 0, 0, 0)',
              backgroundColor: 'rgba(255, 255, 255, 1)',
            })}
          >
            <img src={UpPng} alt="Go to top"></img>
          </CircularProgressbarWithChildren>
        </div>
      </>
    )

    return (
      <Layout
        location={this.props.location}
        optionalFloatingBar={renderFloatingBar()}
        title={siteTitle}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article
          className={`post-content${
            post.frontmatter.thumbnail ? '' : ` no-image`
          }`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p className="post-content-excerpt">
              {post.frontmatter.description}
            </p>
          )}

          {post.frontmatter.thumbnail && (
            <div className="post-content-image">
              <Img
                className="kg-image"
                fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                alt={post.frontmatter.title}
              />
            </div>
          )}

          <p className="text-align-right pull-right">
            Posted on {post.frontmatter.date}
          </p>

          <h2 className="price">
            Price: {post.frontmatter.price || 'Pleasse ask for price'}
          </h2>

          {renderNoteAndDetailTable()}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <footer ref={this.postFooter} className="post-content-footer">
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
            <Bio title={post.frontmatter.title} />
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        price
        note
        condition
        brand
        model
        quantity
        upc
        description
        authorToken
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
