/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import { useStaticQuery, graphql /*, navigate*/ } from 'gatsby'
import Image from 'gatsby-image'
import RainbowText from './rainbow-text'
import Form from './form'

const Bio = props => {
  const { title } = props

  const data = useStaticQuery(
    graphql`
      query BioQuery {
        avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  )

  const {
    site: {
      // TODO: Allow each post to have different authorName
      siteMetadata: { author: authorName },
    },
    avatar: {
      childImageSharp: { fixed },
    },
  } = data

  /*
  const handleContactLinkClick = e => {
    if (
      typeof optionalAuthorToken === 'string' &&
      optionalAuthorToken.length > 0
    ) {
      e.preventDefault()
      navigate('/contact/', {
        replace: false,
        state: { authorToken: optionalAuthorToken },
      })
    }
  }
  */

  return (
    <section className="text-align-center">
      <Image
        fixed={fixed}
        alt={authorName}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Posted by <RainbowText>{authorName}</RainbowText>
      </p>
      <Form itemName={title} />
    </section>
  )
}

export default Bio
